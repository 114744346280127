<template>
  <component-frame title="Page Headers">
    <div class="sg-page__heading">
      <div class="on-this-page">
        <div class="on-this-page-title">On This Page</div>
        <ul class="on-this-page-anchor-list">
          <li><a href="#" v-scroll-to="'#basic'">Basic Header</a></li>
          <li>
            <a href="#" v-scroll-to="'#basic-cta'">Basic Header With Call to Action</a>
          </li>
          <li><a href="#" v-scroll-to="'#package'">Package Header</a></li>
          <li>
            <a href="#" v-scroll-to="'#application'">Application Header</a>
          </li>
        </ul>
      </div>
    </div>

    <h3 id="basic">Basic Header</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="page-header">
            <div class="container-fluid">
              <nav aria-label="breadcrumb" class="d-print-none">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/" class="active">
                      <svg class="icon icon-home">
                        <use xlink:href="/icons/symbol-defs.svg#icon-home"></use>
                      </svg>
                    </a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="#" class="active">Parent</a>
                  </li>
                  <li class="breadcrumb-item active" style="cursor: default;">
                    Current
                  </li>
                </ol>
              </nav>
              <div class="row">
                <div class="col-sm-9">
                  <h1 class="page-title">Page Title<br /></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="page-header"&gt;
  &lt;div class="container-fluid"&gt;
    &lt;nav aria-label="breadcrumb" class="d-print-none"&gt;
      &lt;ol class="breadcrumb"&gt;
        &lt;li class="breadcrumb-item"&gt;
          &lt;a href="/" class="active"&gt;
            &lt;svg class="icon icon-home"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-home"&gt;&lt;/use&gt;&lt;/svg&gt;
          &lt;/a&gt;
        &lt;/li&gt;
        &lt;li class="breadcrumb-item"&gt;&lt;a href="#" class="active"&gt;Parent&lt;/a&gt;&lt;/li&gt;
        &lt;li class="breadcrumb-item active" style="cursor: default;"&gt;Current&lt;/li&gt;
      &lt;/ol&gt;
    &lt;/nav&gt;
    &lt;div class="row"&gt;
      &lt;div class="col-sm-9"&gt;
        &lt;h1 class="page-title"&gt;Page Title&lt;br&gt;
      &lt;/h1&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
    </div>

    <h3 id="basic-cta">Basic Header With Call to Action</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="page-header">
            <div class="container-fluid">
              <nav aria-label="breadcrumb" class="d-print-none">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/" class="active">
                      <svg class="icon icon-home">
                        <use xlink:href="/icons/symbol-defs.svg#icon-home"></use>
                      </svg>
                    </a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="#" class="active">Parent</a>
                  </li>
                  <li class="breadcrumb-item active" style="cursor: default;">
                    Current
                  </li>
                </ol>
              </nav>
              <div class="d-lg-flex align-items-end justify-content-between align-items-center">
                <h1 class="page-title mb-3 mb-md-2">Page Title</h1>
                <a class="btn btn-lg btn-info mb-2" href="#"
                  ><svg class="icon icon-add">
                    <use xlink:href="/icons/symbol-defs.svg#icon-add"></use>
                  </svg>
                  Call to Action</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="page-header"&gt;
  &lt;div class="container-fluid"&gt;
    &lt;nav aria-label="breadcrumb" class="d-print-none"&gt;
      &lt;ol class="breadcrumb"&gt;
        &lt;li class="breadcrumb-item"&gt;
          &lt;a href="/" class="active"&gt;
            &lt;svg class="icon icon-home"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-home"&gt;&lt;/use&gt;&lt;/svg&gt;
          &lt;/a&gt;
        &lt;/li&gt;
        &lt;li class="breadcrumb-item"&gt;&lt;a href="#" class="active"&gt;Parent&lt;/a&gt;&lt;/li&gt;
        &lt;li class="breadcrumb-item active" style="cursor: default;"&gt;Current&lt;/li&gt;
      &lt;/ol&gt;
    &lt;/nav&gt;
    &lt;div class="d-lg-flex align-items-end justify-content-between align-items-center"&gt;
      &lt;h1 class="page-title mb-3 mb-md-2"&gt;Page Title&lt;/h1&gt;
      &lt;a class="btn btn-lg btn-info mb-2" href="#"&gt;&lt;svg class="icon icon-add"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-add"&gt;&lt;/use&gt;&lt;/svg&gt; Call to Action&lt;/a&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
    </div>

    <h3 id="package">Package Header</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <package-header></package-header>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="page-header"&gt;
  &lt;div class="container-fluid"&gt;
    &lt;nav aria-label="breadcrumb" class="d-print-none"&gt;
      &lt;ol class="breadcrumb"&gt;
        &lt;li class="breadcrumb-item"&gt;
          &lt;a href="/" class="active"&gt;
            &lt;svg class="icon icon-home"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-home"&gt;&lt;/use&gt;&lt;/svg&gt;
          &lt;/a&gt;
        &lt;/li&gt;
        &lt;li class="breadcrumb-item"&gt;&lt;a href="#" class="active"&gt;Parent&lt;/a&gt;&lt;/li&gt;
        &lt;li class="breadcrumb-item active" style="cursor: default;"&gt;Current&lt;/li&gt;
      &lt;/ol&gt;
    &lt;/nav&gt;
    &lt;div class="d-lg-flex align-items-end justify-content-between"&gt;
      &lt;div&gt;
        &lt;h1 class="page-title"&gt;Page Title&lt;/h1&gt;
        &lt;h3 class="page-title-sub"&gt;&lt;a href="#"&gt;Package Link&lt;/a&gt;&lt;/h3&gt;
        &lt;ul class="list-inline mb-1"&gt;
          &lt;li class="list-inline-item"&gt;
            &lt;span class="text-muted"&gt;Package:&lt;/span&gt; &lt;strong&gt;Land Title&lt;/strong&gt;
          &lt;/li&gt;
          &lt;li class="list-inline-item"&gt;
            &lt;span class="text-muted"&gt;status:&lt;/span&gt;
            &lt;strong&gt;
              Unsubmitted
              &lt;a href="#" data-toggle="popover" data-placement="top" data-title="Help Text" data-content="This is an example of some help text" data-original-title="" title="" aria-describedby="popover96323" v-popover&gt;
                &lt;svg class="icon icon-help"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-help"&gt;&lt;/use&gt;&lt;/svg&gt;
              &lt;/a&gt;
            &lt;/strong&gt;
          &lt;/li&gt;
        &lt;/ul&gt;
      &lt;/div&gt;
      &lt;ul class="nav mt-3-md mt-lg-1 d-print-none"&gt;
        &lt;li class="nav-item"&gt;
          &lt;button class="btn btn-md btn-link pl-0 pl-lg-2 pr-2 dropdown-toggle" id="managePackage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"&gt;Manage Package&lt;/button&gt;
          &lt;div class="dropdown-menu" aria-labelledby="managePackage"&gt;
            &lt;a class="dropdown-item" href="javascript:void(0)"&gt;Download Package&lt;/a&gt;
            &lt;a class="dropdown-item" href="javascript:void(0)"&gt;Delete Package&lt;/a&gt;
          &lt;/div&gt;
        &lt;/li&gt;
        &lt;li class="nav-item mr-1"&gt;&lt;a class="btn btn-md btn-secondary px-2" href="javascript:void(0)"&gt;E-sign&lt;/a&gt;&lt;/li&gt;
        &lt;li class="nav-item"&gt;
          &lt;a class="btn btn-md btn-secondary mt-1 mt-sm-0 px-2" href="javascript:void(0)"&gt;
            Prepare Submission
            &lt;svg class="icon icon-arrow_cta"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"&gt;&lt;/use&gt;&lt;/svg&gt;
          &lt;/a&gt;
        &lt;/li&gt;
      &lt;/ul&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
    </div>

    <h3 id="application">Application header</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <a href="#" class="mr-2" to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1">
                <use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use>
              </svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </a>

            <!-- Change instrument dropdown -->
            <a
              href="#"
              class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block"
              id="instrument"
              data-toggle="dropdown"
              aria-controls="instrument-contents"
              aria-expanded="false"
            >
              Change Application
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:void(0)">{name} <span class="badge badge-success">Status</span></a>
            </div>
          </div>

          <!-- Package title and actions -->
          <div class="d-md-flex align-items-start justify-content-between">
            <div>
              <h2 class="page-title mr-2">
                {Application Name}
                <button class="btn btn-sm btn-link p-0">
                  <svg class="icon icon-edit mr-sm-1">
                    <use xlink:href="/icons/symbol-defs.svg#icon-edit"></use>
                  </svg>
                </button>
              </h2>
              <small
                ><span class="text-muted">Application Type:</span>
                <strong>{Application Type}</strong>
                <button class="btn btn-sm btn-secondary ml-sm-1">
                  Change
                </button></small
              >
              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small><span class="text-muted text-small">status:</span> <strong>{status}</strong></small>
                </li>
                <li class="list-inline-item">
                  <small><span class="text-muted">Modified:</span> <strong>{date}</strong></small>
                </li>
              </ul>
            </div>

            <!-- Drawer toggle and delete package -->
            <div class="d-flex">
              <button
                class="btn btn-md btn-secondary d-md-none mb-2 mr-auto"
                type="button"
                data-toggle="collapse"
                data-target="#application-nav"
                aria-expanded="false"
                aria-controls="application-nav"
              >
                <svg class="icon icon-more">
                  <use xlink:href="/icons/symbol-defs.svg#icon-more"></use>
                </svg>
                <svg class="icon icon-close">
                  <use xlink:href="/icons/symbol-defs.svg#icon-close"></use>
                </svg>
                Navigate Application
              </button>
              <button type="button" class="btn btn-md btn-secondary mb-2">
                <svg class="icon icon-edit d-md-none">
                  <use xlink:href="/icons/symbol-defs.svg#icon-edit"></use>
                </svg>
                <span class="d-none d-md-block">Edit</span>
              </button>
              <button type="button" class="btn btn-md btn-danger mb-2 ml-1">
                <svg class="icon icon-delete d-md-none">
                  <use xlink:href="/icons/symbol-defs.svg#icon-delete"></use>
                </svg>
                <span class="d-none d-md-block">Delete Application</span>
              </button>
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="mb-3 border-bottom pb-3"&gt;
  &lt;!-- Back to applications link --&gt;
  &lt;a href="#" class="mr-2"&gt;
    &lt;svg class="icon icon-arrow_cta_back mr-sm-1"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"&gt;&lt;/use&gt;&lt;/svg&gt;
    &lt;span class="d-none d-sm-inline-block"&gt;Return to Applications&lt;/span&gt;
    &lt;span class="d-sm-none"&gt;Back&lt;/span&gt;
  &lt;/a&gt;

  &lt;!-- Change instrument dropdown --&gt;
  &lt;a href="#" class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block" id="instrument" data-toggle="dropdown" aria-controls="instrument-contents" aria-expanded="false"&gt;
    Change Application
    &lt;span class="caret"&gt;&lt;/span&gt;
  &lt;/a&gt;
  &lt;div class="dropdown-menu" aria-labelledby="dropdownMenuButton"&gt;
    &lt;a class="dropdown-item" href="javascript:void(0)"&gt;{name} &lt;span class="badge badge-success"&gt;Status&lt;/span&gt;&lt;/a&gt;
  &lt;/div&gt;
  &lt;/div&gt;

  &lt;!-- Package title and actions --&gt;
  &lt;div class="d-md-flex align-items-start justify-content-between"&gt;
  &lt;div&gt;
    &lt;h2 class="page-title mr-2"&gt;
      {Application Name}
      &lt;button class="btn btn-sm btn-link p-0"&gt;&lt;svg class="icon icon-edit mr-sm-1"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-edit"&gt;&lt;/use&gt;&lt;/svg&gt;&lt;/button&gt;
    &lt;/h2&gt;
    &lt;small&gt;&lt;span class="text-muted"&gt;Application Type:&lt;/span&gt; &lt;strong&gt;{Application Type}&lt;/strong&gt; &lt;button class="btn btn-sm btn-secondary ml-sm-1"&gt;Change&lt;/button&gt;&lt;/small&gt;
    &lt;ul class="list-inline mb-1"&gt;
      &lt;li class="list-inline-item"&gt;
        &lt;small&gt;&lt;span class="text-muted text-small"&gt;status:&lt;/span&gt; &lt;strong&gt;{status}&lt;/strong&gt;&lt;/small&gt;
      &lt;/li&gt;
      &lt;li class="list-inline-item"&gt;
        &lt;small&gt;&lt;span class="text-muted"&gt;Modified:&lt;/span&gt; &lt;strong&gt;{date}&lt;/strong&gt;&lt;/small&gt;
      &lt;/li&gt;
    &lt;/ul&gt;
  &lt;/div&gt;

  &lt;!-- Drawer toggle and delete package --&gt;
  &lt;div class="d-flex"&gt;
    &lt;button class="btn btn-md btn-secondary d-md-none mb-2 mr-auto" type="button" data-toggle="collapse" data-target="#application-nav" aria-expanded="false" aria-controls="application-nav"&gt;
      &lt;svg class="icon icon-more"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-more"&gt;&lt;/use&gt;&lt;/svg&gt;
      &lt;svg class="icon icon-close"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-close"&gt;&lt;/use&gt;&lt;/svg&gt;
      Navigate Application
    &lt;/button&gt;
    &lt;button type="button" class="btn btn-md btn-secondary mb-2"&gt;
      &lt;svg class="icon icon-edit d-md-none"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-edit"&gt;&lt;/use&gt;&lt;/svg&gt;
      &lt;span class="d-none d-md-block"&gt;Edit&lt;/span&gt;
    &lt;/button&gt;
    &lt;button type="button" class="btn btn-md btn-danger mb-2 ml-1"&gt;
      &lt;svg class="icon icon-delete d-md-none"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-delete"&gt;&lt;/use&gt;&lt;/svg&gt;
      &lt;span class="d-none d-md-block"&gt;Delete Application&lt;/span&gt;
    &lt;/button&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
    </div>
  </component-frame>
</template>

<script>
export default {
  name: "tabs",
  data() {
    return {
      title: "Page Headers",
      menu: false,
    };
  },
};
</script>
